import React from 'react'
import { Devis } from './Devis'
import { Faq } from '../Faq/Faq'
import { Divers } from '../Divers/Divers'

export const DevisPage = () => {
  return (
    <>
    <Devis/>
    <Faq/>
    <Divers/>
    </>
  )
}
