import React from 'react'
import "./Devis.css"
import { DevisContact } from './DevisContact'

export const Devis = () => {
    return (
        <section id='devis'>
            <div className='primary-overlay'>
            <div  className='container'>
                <div className='row'>
                    <div className='col-lg-8'>
                        <h4 className='devisTitle'>DEMANDER UN DEVIS</h4>
                    </div>
                    <div className='col-lg-4'>

                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-8 flexColArround'>
                        <div className='row w100'>
                            <div className='col-md-6'>
                                <div class="input-group  input-group-lg mb-3">

                                    <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" style={{height:57}} placeholder=" Votre nom*"/>
                                </div>

                            </div>
                            <div className='col-md-6'>
                                <div class="input-group  input-group-lg mb-3">

                                    <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" style={{height:57}} placeholder=" Votre prenom*"/>
                                </div>

                            </div>
                        </div>
                        <div className='row w100'>
                            <div className='col-md-6'>
                                <div class="input-group  input-group-lg mb-3">

                                    <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" style={{height:57}} placeholder="Votre adresse"/>
                                </div>

                            </div>
                            <div className='col-md-6'>
                                <div class="input-group  input-group-lg mb-3">

                                    <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" style={{height:57}} placeholder=" Code Postal*"/>
                                </div>

                            </div>
                        </div>
                        <div className='row w100'>
                            <div className='col-md-6'>
                                <div class="input-group  input-group-lg mb-3">

                                    <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" style={{height:57}} placeholder="Ville*"/>
                                </div>

                            </div>
                            <div className='col-md-6'>
                                <div class="input-group  input-group-lg mb-3">

                                    <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" style={{height:57}} placeholder="Numéro de téléphone*"/>
                                </div>

                            </div>
                        </div>
                        <div className='row w100'>
                            <div className='col-md-6'>
                                <div class="input-group  input-group-lg mb-3">

                                    <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" style={{height:57}} placeholder="Adresse e-mail*"/>
                                </div>

                            </div>
                            <div className='col-md-6'>
                                <div class="input-group  input-group-lg mb-3">

                                    <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" style={{height:57}} placeholder="Le type de service souhaité *"/>
                                </div>

                            </div>
                        </div>
                        <div className='row w100'>
                            <div className='col-md-6'>
                                <div class="input-group  input-group-lg mb-3">

                                    <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" style={{height:57}} placeholder="La capacité de votre cuve à fioul"/>
                                </div>

                            </div>
                            <div className='col-md-6'>
                                <div class="input-group  input-group-lg mb-3">

                                    <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" style={{height:57}} placeholder='Cuve enterrée / Cuve non enterrée*'/>
                                </div>

                            </div>
                        </div>
                        <div className='row w100'>
                            <div className='col-md-6'>
                                <div class="input-group  input-group-lg mb-3">

                                    <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" style={{height:57}} placeholder="Accessibilité"/>
                                </div>

                            </div>
                            <div className='col-md-6'>
                                <div class="input-group  input-group-lg mb-3">

                                    <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" style={{height:57}} placeholder='Prise électrique à proximité'/>
                                </div>

                            </div>
                        </div>
                        <div className='row w100'>
                            <div className='col-md-12'>
                                <div class="input-group  input-group-lg mb-3">
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="5" placeholder='Message ou toute autre information que vous jugez utile'></textarea>
                                </div>

                            </div>
                            <div className='col-lg-12 flexColCenter m-2'>
<button className='filledButton'>Envoyer</button>
                    </div>

                        </div>



                    </div>
                    <div className='col-lg-4 rmPadding'>
                    
                    <DevisContact/>

                    </div>


                </div>

                {/* <div className='row'>
                    <div className='col-lg-8 flexColCenter m-2'>
<button className='filledButton'>Envoyer</button>
                    </div>
                    <div className='col-lg-4'>

                    </div>
                </div> */}
            </div>
            </div>
        </section>
    )
}
