import React from 'react'
import brandLogo from "../Styling/img/loginLogo.svg"
import { LoginBox } from './LoginBox'
import "./Login.css"

export const LoginPage = () => {
  return (
    <section id='loginPage'>
        <div className='container'>
        <div className="primary-overlay loginFlexStart">
          <LoginBox brandLogo={brandLogo}/>
          </div>
        </div>
        
    </section>
  )
}
