import React from 'react'
import {Title} from '../Title/Title'
import './Privacy.css'

export const Privacy = () => {
  return (
    <section id='privacy'>
        <div className='container p-4'>
            <Title text={"PRIVACY POLICY"}/>
            <div className='privacyP'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dapibus justo quis mattis hendrerit. Nullam in ex eget ipsum dignissim tempor. Morbi sed nisl faucibus, sodales mi nec, rutrum mi. Curabitur luctus aliquet vulputate. Duis eu elit vitae mi gravida aliquet at tempus dolor. Nam lorem erat, malesuada sed hendrerit sed, malesuada a velit. Mauris lobortis, tortor sed bibendum suscipit, elit nunc elementum urna, ac aliquet arcu erat a tellus. Sed feugiat nec sem luctus venenatis. Nulla quis scelerisque nibh, id consequat velit. Vestibulum at nisi neque. Nam sodales nisl sed nisl vulputate, vitae blandit tortor pharetra. Duis elit metus, feugiat rutrum justo id, sagittis pulvinar ipsum. Pellentesque id dolor purus. Aenean lorem lorem, placerat a est in, faucibus scelerisque dolor.
            Morbi egestas metus eu massa maximus, ac maximus enim pulvinar. Donec tristique at ligula ut tincidunt. Aliquam luctus velit metus, et rutrum quam sagittis eget. Aliquam erat volutpat. Sed vulputate lorem ac ex viverra finibus. Phasellus et risus iaculis, malesuada quam at, porta orci. In hac habitasse platea dictumst. Pellentesque viverra, mi sollicitudin mollis bibendum, lacus orci aliquet sapien, ut ullamcorper mi erat et erat. Suspendisse sit amet fringilla nunc. Sed dapibus massa sed lorem congue, vitae consectetur felis accumsan. Nunc in mauris consectetur, rhoncus leo sit amet, sollicitudin nunc. Nulla et mauris eu libero convallis vulputate ut id dui.</div>
            <div className='privacyP'>
                <span className='privacyInitials'>Morbi egestas metus eu massa maximus, ac maximus enim pulvinar </span>: Donec tristique at ligula ut tincidunt. Aliquam luctus velit metus, et rutrum quam sagittis eget. Aliquam erat volutpat. Sed vulputate lorem ac ex viverra finibus. Phasellus et risus iaculis, malesuada quam at, porta orci. In hac habitasse platea dictumst. Pellentesque viverra, mi sollicitudin mollis bibendum, lacus orci aliquet sapien, ut ullamcorper mi erat et erat. Suspendisse sit amet fringilla nunc. Sed dapibus massa sed lorem congue, vitae consectetur felis accumsan. Nunc in mauris consectetur, rhoncus leo sit amet, sollicitudin nunc. Nulla et mauris eu libero convallis vulputate ut id dui.
            </div>
            <div className='privacyP'>
                <span className='privacyInitials'>Morbi egestas metus eu massa maximus, ac maximus enim pulvinar </span>: Donec tristique at ligula ut tincidunt. Aliquam luctus velit metus, et rutrum quam sagittis eget. Aliquam erat volutpat. Sed vulputate lorem ac ex viverra finibus. Phasellus et risus iaculis, malesuada quam at, porta orci. In hac habitasse platea dictumst. Pellentesque viverra, mi sollicitudin mollis bibendum, lacus orci aliquet sapien, ut ullamcorper mi erat et erat. Suspendisse sit amet fringilla nunc. Sed dapibus massa sed lorem congue, vitae consectetur felis accumsan. Nunc in mauris consectetur, rhoncus leo sit amet, sollicitudin nunc. Nulla et mauris eu libero convallis vulputate ut id dui.
            </div>
            <div className='privacyP'>
                <span className='privacyInitials'>Morbi egestas metus eu massa maximus, ac maximus enim pulvinar </span>: Donec tristique at ligula ut tincidunt. Aliquam luctus velit metus, et rutrum quam sagittis eget. Aliquam erat volutpat. Sed vulputate lorem ac ex viverra finibus. Phasellus et risus iaculis, malesuada quam at, porta orci. In hac habitasse platea dictumst. Pellentesque viverra, mi sollicitudin mollis bibendum, lacus orci aliquet sapien, ut ullamcorper mi erat et erat. Suspendisse sit amet fringilla nunc. Sed dapibus massa sed lorem congue, vitae consectetur felis accumsan. Nunc in mauris consectetur, rhoncus leo sit amet, sollicitudin nunc. Nulla et mauris eu libero convallis vulputate ut id dui.
            </div>
            <div className='privacyP mb-4'>
                <span className='privacyInitials'>Morbi egestas metus eu massa maximus, ac maximus enim pulvinar </span>: Donec tristique at ligula ut tincidunt. Aliquam luctus velit metus, et rutrum quam sagittis eget. Aliquam erat volutpat. Sed vulputate lorem ac ex viverra finibus. Phasellus et risus iaculis, malesuada quam at, porta orci. In hac habitasse platea dictumst. Pellentesque viverra, mi sollicitudin mollis bibendum, lacus orci aliquet sapien, ut ullamcorper mi erat et erat. Suspendisse sit amet fringilla nunc. Sed dapibus massa sed lorem congue, vitae consectetur felis accumsan. Nunc in mauris consectetur, rhoncus leo sit amet, sollicitudin nunc. Nulla et mauris eu libero convallis vulputate ut id dui.
            </div>
            
        </div>
    </section>
  )
}
