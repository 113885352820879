import React from 'react'
import { Contact } from './Contact'
import { Faq } from '../Faq/Faq'
import { Divers } from '../Divers/Divers'


export const ContactPage = () => {
  return (
    <>
     <Contact/>
    <Faq/>
    <Divers/>
    </>
   


  )
}
