import React, { useState } from 'react';
import vector from "../Styling/img/Vector.svg";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import polygone from '../Styling/img/Polygon.svg'

export const Header3 = ({isopen}) => {
  const [show, setShow] = useState(false);
  // const [longueur,setLongueur]=useState(120)

  const toggleShow = () => {
    setShow(prevState => !prevState);
  
  };

  // const toggleLongueur=()=>{
  //   switch(longueur){
  //     case 120:
  //       setLongueur(240)
  //       break
  //     default:
  //       setLongueur(120)
  //   }
  // }

  const handleClickInside = (e) => {
    e.stopPropagation();
  };

  return (
    <div id='header3' className={isopen?"flexRowCenter fixed-top-3-bis":"flexRowCenter fixed-top-3"}>
      <div className='flexColCenter' style={{ textAlign: "center", color: "white", paddingInline: 20 }}>
        <p className='header3P'>
          Un conseil pour vos travaux de nettoyage ou de dépose de votre cuve ? on vous rappelle immédiatement, 
          <span 
            onClick={toggleShow} 
            style={{ textDecoration: "underline", fontWeight: "500", marginRight: 5, cursor: 'pointer', position: 'relative' }}
          >
            cliquez ici
            {show && (
              <>
              <img src={polygone} className='polygone'/>
              <div 
                className='fluid-container pop-up' 
                
                onClick={handleClickInside}
              >
                <div className='flexColCenter'>
                  <div className='text1' style={{width:"100%",textAlign:"start",color:"black"}}>Let’s schedule our call</div>
                    <div className="input-group mb-3" style={{width:"100%"}}>
                      <span className="input-group-text" id="basic-addon1" style={{ width: "18%",fontSize:15,backgroundColor:"white" }}><span className="fi fi-fr"></span></span>
                      <input type="text" className="form-control" placeholder="+33  00 00 00 00 00" aria-label="Username" aria-describedby="basic-addon1" style={{width: "82%",fontSize:10,borderRadius:"0px 10px 10px 0px" }}/>
                    </div>
                 
                  <div>
                    <div className="input-group mb-3">
                      <div className="form-select" id="inputGroupSelect01" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" style={{textAlign:"start", height: "30px", width: "260px",fontSize:12,fontFamily: 'Roboto', fontWeight: "400",borderRadius:10}} >
                        {/* <option selected style={{fontSize:12,fontFamily: 'Roboto',fontWeight: "400",borderBottom:"solid 0.5px gray"}}>Choose the time </option>
                        <option value="1" style={{fontSize:12,fontFamily: 'Roboto',fontWeight: "400",borderBottom:"solid 0.5px gray"}}>One</option>
                        <option value="2" style={{fontSize:12,fontFamily: 'Roboto',fontWeight: "400",borderBottom:"solid 0.5px gray"}}>Two</option>
                        <option value="3" style={{fontSize:12,fontFamily: 'Roboto',fontWeight: "400",borderBottom:"solid 0.5px gray"}}>Three</option> */}
                        Choose the time 
                      </div>
                      <div class="collapse" id="collapseExample" style={{width:"100%"}}>
  <div class="card card-body">
   <ul className='horaire'>
    <li className='horaire-item' onClick={()=>alert('Not set yet')}>08am - 10am</li>
    <li className='horaire-item' onClick={()=>alert('Not set yet')}>10am - 12am</li>
    <li className='horaire-item' onClick={()=>alert('Not set yet')}>02pm - 04pm</li>
    <li className='horaire-item' onClick={()=>alert('Not set yet')}>04pm - 06pm</li>
   </ul>
   
  </div>
  <a href="#" class="btn" style={{backgroundColor:'#E75D0E',color:'white',marginTop:10,width:100,height:30,fontSize:12}}>Envoyer</a>
</div>
                    </div>
                  </div>
                </div>
              </div></>
            )}
          </span>
          <img src={vector} alt="vector icon" />
        </p>
      </div>
    </div>
  );
};
