import React from 'react'
import arrow from '../Styling/img/lened_arrow.svg'

export const LoginBox = ({brandLogo}) => {
  return (
    <div className="flexColBetween loginBox">
      <div className="card p-4 text-center" style={{width:"80%",height:"100%",backgroundColor: 'rgba(0, 0, 0, 0.7)', color: '#fff', borderRadius: '15px' }}>
        <div className="text-center">
          <img src={brandLogo} alt="Brand Logo" style={{  marginBottom: '20px' }} />
          <h2 className='loginTitle'>MON ESPACE</h2>
        </div>
        <form> 
          <div className="mb-3">
        
            <input type="email" className="form-control inputCredentials" id="email" placeholder="Email" />
          </div>
          <div className="mb-1">
           
            <input type="password" className="form-control inputCredentials" id="password" placeholder="Mot de passe" />
          </div>
          <div className='flexRowBetween'>
          <div class="form-check flexRowStart">
  <input class="form-check-input check-box" type="checkbox" value="" id="flexCheckDefault"/>
  <label class="form-check-label check-box-typo" for="flexCheckDefault">
  Remember me
  </label>
</div>
<div className='check-box-typo' style={{cursor:"pointer"}} onClick={()=>alert('Not set yet')}>Mot de passe  oublié ?</div>
          </div>
          <div className='text-center marTop'>
          <button type="submit" className="filledButton">Se connecter</button>
          </div>
        </form>
        <div className="text-center mt-5">
          <p className='loginPrompt'>Pas encore inscrit ? <a href="#" style={{textDecoration:"none",color:"#E75D0E"}}>Créer un compte<img src={arrow} className='leaned_arrow'/></a></p>
        </div>
      </div>
    </div>
  )
}
