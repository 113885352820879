import React from 'react';
import "./Faq.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IconContext } from 'react-icons';

export const FaqCollapse = ({ paragraphe, question, id, isOpen, onToggle }) => {
  const collapsingIcon = () => (
    <IconContext.Provider value={{ className: "iconFaq" }}>
      <div>
        {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </div>
    </IconContext.Provider>
  );

  return (
    <div style={{ width: "100%" }}>
      <div className='flexRowBetween'  onClick={onToggle}>
        <p className='faqQuestion'>{question}</p>
        <div>
          {collapsingIcon()}
        </div>
      </div>
      
      <div className={`collapse ${isOpen ? 'show' : ''}`} id={`collapse_${id}`}>
        <div className="card card-body faqParagraphe">
          {paragraphe}
        </div>
      </div>
    </div>
  );
};
