import React, { useState } from 'react';
import { FaqCollapse } from './FaqCollapse';
import faqImage from "../Styling/img/faqImage2.jpg";
import { Title } from '../Title/Title';

export const Faq = () => {
  const [openFaqId, setOpenFaqId] = useState(null);

  const handleToggle = (id) => {
    setOpenFaqId(prevId => (prevId === id ? null : id));
  };

  return (
    <section id='faq' className='flexColCenter'>
      <div className='flexRowCenter' style={{ width: "100%" }}>
        <div>
          <img src={faqImage} className='faqImage' alt="FAQ" />
        </div>
        <div className='flexColArround faqCollapsSection'>
          <Title text={"FAQ"} />
          <FaqCollapse
            paragraphe={"Un nettoyage régulier de votre cuve à fioul prolonge sa durée de vie et optimise son efficacité. Cela permet également d'éviter les pannes et les problèmes de combustion."}
            question={"Quels sont les avantages de faire nettoyer ma cuve à fioul régulièrement ?"}
            id={1}
            isOpen={openFaqId === 1}
            onToggle={() => handleToggle(1)}
          /><hr style={{ color: 'black', width: "100%" }} />
          <FaqCollapse
            paragraphe={"Un nettoyage régulier de votre cuve à fioul prolonge sa durée de vie et optimise son efficacité. Cela permet également d'éviter les pannes et les problèmes de combustion."}
            question={"Comment se déroule la neutralisation d'une cuve hors d'usage ?"}
            id={2}
            isOpen={openFaqId === 2}
            onToggle={() => handleToggle(2)}
          /><hr style={{ color: 'black', width: "100%" }} />
          <FaqCollapse
            paragraphe={"Un nettoyage régulier de votre cuve à fioul prolonge sa durée de vie et optimise son efficacité. Cela permet également d'éviter les pannes et les problèmes de combustion."}
            question={"Combien de temps prend la dépose d'une cuve à fioul ?"}
            id={3}
            isOpen={openFaqId === 3}
            onToggle={() => handleToggle(3)}
          /><hr style={{ color: 'black', width: "100%" }} />
          <FaqCollapse
            paragraphe={"Un nettoyage régulier de votre cuve à fioul prolonge sa durée de vie et optimise son efficacité. Cela permet également d'éviter les pannes et les problèmes de combustion."}
            question={"Quels sont les risques d'une cuve à fioul mal entretenue ?"}
            id={4}
            isOpen={openFaqId === 4}
            onToggle={() => handleToggle(4)}
          /><hr style={{ color: 'black', width: "100%" }} />
          <FaqCollapse
            paragraphe={"Un nettoyage régulier de votre cuve à fioul prolonge sa durée de vie et optimise son efficacité. Cela permet également d'éviter les pannes et les problèmes de combustion."}
            question={"Quels sont les risques d'une cuve à fioul mal entretenue ?"}
            id={5}
            isOpen={openFaqId === 5}
            onToggle={() => handleToggle(5)}
          /><hr style={{ color: 'black', width: "100%" }} />
        </div>
      </div>
    </section>
  );
};
