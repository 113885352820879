import React,{useState,useEffect} from 'react'
import './Homepage.css'
import { Services } from '../Services/Services'
import { Faq } from '../Faq/Faq'
import { Devis } from '../Devis/Devis'
import { Divers } from '../Divers/Divers'
import { About } from '../About/About'
import { Link } from 'react-router-dom'
import CookieConsent from '../CookiesConsent/CookiesConsent'

export const Homepage = () => {

  return (
    <>
    {/* <CookieConsent/> */}
    <section id="showcase" className="py-5">
    <div className="primary-overlay">
        <div className="container" style={{height:"100%"}}>
            <div className="row"  style={{height:"100%"}}>
                <div className="col-lg-6 text-white flexColCenter" style={{height:"100%" }}>
                    <h4 className="bannerText">
                    Experts en nettoyage et dépose de cuves à fioul en France
                    </h4>
                    <div style={{width:"100%",marginTop:30}}>
                    <Link to={'/Contact'} className='filledButton'> Contactez-nous</Link>
                    </div>
                   
                </div>
            </div> 
        </div>
    </div>
</section>
<Services />
<Devis />
<Faq />
<Divers/>
</>
  )
}
